<template>
  <div>
    <p class="text">待开发中</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0f0f0; /* 可选背景色 */
    }

    .text {
      font-size: 30px; /* 合适的字体大小 */
      font-weight: bold; /* 加粗 */
      color: #333; /* 文字颜色 */
    }
</style>